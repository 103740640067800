/**
 * Returns a shallow clone of an object, setting or overriding the specified property with the given value
 * @param {string} prop
 * @param {any} newValue
 * @param {obj} obj
 * @returns {object}
 */
export function mapAssoc(prop, newValue, obj) {
  for (const key in obj) {
    obj[key] = { ...obj[key], [prop]: newValue }
  }
  return obj
}

/**
 * Create a new object with the own properties of the first object merged with
 *  the own properties of the second object. If a key exists in both objects,
 * the value from the second object will be used.
 * @example
 *
 *      Herz.Utils.Object.mergeRight({ 'country': 'Sweden', 'city': 'Malmo' }, { 'city': 'Lund' });
 *      //=> { 'country': 'Sweden', 'city': 'Lund' }
 */

export function mergeRight(target = {}, source = {}) {
  return Object.assign(target, source)
}

export function rejectNullKeys(obj) {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key]
    }
  }
}

export function mergeDeepRight(target = {}, source = {}) {
  return Object.entries(source).reduce((o, [k, v]) => {
    o[k] =
      v && typeof v === 'object'
        ? mergeDeepRight((o[k] = o[k] || (Array.isArray(v) ? [] : {})), v)
        : v
    return o
  }, target)
}
