import * as React from 'react'

import styled from '@emotion/styled'

export const SvgIcon = styled.svg`
  margin-top: -0.105em;
  margin-bottom: -0.145em;
  height: 1.25em;
  width: 1.25em;
  vertical-align: -0.185em;
`

export function Add() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path fill="currentColor" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </SvgIcon>
  )
}

export function ArrowRightAlt() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
    </SvgIcon>
  )
}

export function BankNote() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path fill="currentColor" d="M2,5v14h20V5H2z M9,17l-5-5V7h11l5,5v5H9z" />
      <circle fill="currentColor" cx="12" cy="12" r="3" />
    </SvgIcon>
  )
}

export function CoinStack() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M4.086,5C4.562,4.595,6.216,4,8,4s3.438,0.595,3.914,1H14c0-2.243-4.121-3-6-3S2,2.757,2,5v12c0,2.245,4.121,3,6,3c0.287,0,0.631-0.021,1-0.058v-2.009C8.675,17.974,8.342,18,8,18c-1.916,0-3.682-0.684-4-1.086v-0.693C5.32,16.776,6.985,17,8,17c0.288,0,0.631-0.021,1-0.058v-2.009C8.675,14.974,8.342,15,8,15c-1.937,0-3.709-0.697-4-1.098v-0.682C5.32,13.776,6.985,14,8,14c0.288,0,0.631-0.021,1-0.058v-2.009C8.675,11.974,8.342,12,8,12c-1.937,0-3.709-0.697-4-1.098v-0.682C5.32,10.776,6.985,11,8,11c0.288,0,0.631-0.021,1-0.058V8.934C8.675,8.974,8.342,9,8,9C6.063,9,4.291,8.303,4,7.902V7.221C5.32,7.776,6.985,8,8,8c0.288,0,0.631-0.021,1-0.058V5.935C8.675,5.974,8.342,6,8,6C6.216,6,4.562,5.407,4.086,5z"
      />
      <path
        fill="currentColor"
        d="M16,7c-1.879,0-6,0.757-6,3v9c0,2.245,4.121,3,6,3s6-0.755,6-3v-9C22,7.757,17.879,7,16,7z M20,12.902C19.709,13.303,17.937,14,16,14s-3.709-0.697-4-1.098v-0.682C13.32,12.776,14.985,13,16,13s2.68-0.224,4-0.779V12.902z M20,15.902C19.709,16.303,17.937,17,16,17s-3.709-0.697-4-1.098v-0.682C13.32,15.776,14.985,16,16,16s2.68-0.224,4-0.779V15.902z M16,9c1.784,0,3.438,0.595,3.914,1c-0.476,0.407-2.13,1-3.914,1s-3.438-0.593-3.914-1C12.562,9.595,14.216,9,16,9z M16,20c-1.916,0-3.682-0.684-4-1.086v-0.693C13.32,18.776,14.985,19,16,19s2.68-0.224,4-0.779v0.693C19.682,19.316,17.916,20,16,20z"
      />
    </SvgIcon>
  )
}

export function Chat() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"
      />
    </SvgIcon>
  )
}

export function Check() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
      />
    </SvgIcon>
  )
}

export function CheckBold() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  )
}

export function CheckCircle() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
      />
    </SvgIcon>
  )
}

export function Clear() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
    </SvgIcon>
  )
}

export function Edit() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
      />
    </SvgIcon>
  )
}

export function Email() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgIcon>
  )
}

export function Error() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
      />
    </SvgIcon>
  )
}

export function Visibility() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
      />
    </SvgIcon>
  )
}

export function VisibilityOff() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
      />
    </SvgIcon>
  )
}

export function Face() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z"
      />
    </SvgIcon>
  )
}

export function Home() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path fill="currentColor" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  )
}

export function Menu() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
      />
    </SvgIcon>
  )
}

export function Lock() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
      />
    </SvgIcon>
  )
}

export function Loyalty() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7zm11.77 8.27L13 19.54l-4.27-4.27C8.28 14.81 8 14.19 8 13.5c0-1.38 1.12-2.5 2.5-2.5.69 0 1.32.28 1.77.74l.73.72.73-.73c.45-.45 1.08-.73 1.77-.73 1.38 0 2.5 1.12 2.5 2.5 0 .69-.28 1.32-.73 1.77z"
      />
    </SvgIcon>
  )
}

export function LoyaltyColored() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient
          id="gradient"
          x1="6.518%"
          x2="116.948%"
          y1="106.321%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#FFD87A" />
          <stop offset="100%" stopColor="#B532AB" />
        </linearGradient>
      </defs>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="url(#gradient)"
        d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7zm11.77 8.27L13 19.54l-4.27-4.27C8.28 14.81 8 14.19 8 13.5c0-1.38 1.12-2.5 2.5-2.5.69 0 1.32.28 1.77.74l.73.72.73-.73c.45-.45 1.08-.73 1.77-.73 1.38 0 2.5 1.12 2.5 2.5 0 .69-.28 1.32-.73 1.77z"
      />
    </SvgIcon>
  )
}

export function Phone() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
      />
    </SvgIcon>
  )
}

export function Remove() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path fill="currentColor" d="M19 13H5v-2h14v2z" />
    </SvgIcon>
  )
}

export function Room() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
      />
    </SvgIcon>
  )
}

export function Search() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      />
    </SvgIcon>
  )
}

export function Videocam() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
      />
    </SvgIcon>
  )
}

export function ViewModule() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M4 11h5V5H4v6zm0 7h5v-6H4v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5V5h-5v6zm6-6v6h5V5h-5z"
      />
    </SvgIcon>
  )
}

export function ExpandMore() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgIcon>
  )
}

export function KeyboardArrowLeft() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
      />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </SvgIcon>
  )
}

export function Info() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
      />
    </SvgIcon>
  )
}

export function Warning() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      />
    </SvgIcon>
  )
}

export function ExpandLess() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgIcon>
  )
}

export function PlayArrow() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M8 5v14l11-7z" fill="currentColor" />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgIcon>
  )
}

export function Plus() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 27 27"
    >
      <path
        d="M26.2518 17.35H17.5518V26.25H9.45176V17.35H0.751758V9.7H9.45176V0.749999H17.5518V9.7H26.2518V17.35Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Spinner() {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <g fill="none" fillRule="evenodd" strokeWidth="2">
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="0s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="-0.9s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="-0.9s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </SvgIcon>
  )
}

export function Roulette() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      width="1em"
      height="1em"
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill="currentColor"
          d="M12.355 1.799a7.482 7.482 0 0 0-10.556.82 7.487 7.487 0 1 0 10.556-.82zm1.451 7.86l-2.288-.79c.308-.898.304-1.873 0-2.763l2.286-.787c.181.528.296 1.083.34 1.654a6.66 6.66 0 0 1-.338 2.686zm.129-4.403v-.001.001zm-1.413-2.151L10.7 4.687a4.24 4.24 0 0 0-2.393-1.382l.46-2.36a6.648 6.648 0 0 1 3.755 2.16zM5.215 1.21A6.59 6.59 0 0 1 6.21.94l.46 2.366a4.242 4.242 0 0 0-2.394 1.38L2.452 3.1a6.662 6.662 0 0 1 2.763-1.889zM1.168 5.315l2.288.789a4.276 4.276 0 0 0-.001 2.763l-2.288.788a6.718 6.718 0 0 1 0-4.34zm1.28 6.557l1.825-1.586a4.24 4.24 0 0 0 2.393 1.382l-.461 2.374a6.664 6.664 0 0 1-3.757-2.17zm7.31 1.897a6.678 6.678 0 0 1-.993.273l-.46-2.373a4.242 4.242 0 0 0 2.393-1.381l1.824 1.586a6.675 6.675 0 0 1-2.764 1.895z"
        />
        <g fill="currentColor" transform="translate(1 2)">
          <circle cx="3.716" cy=".702" r="1" />
          <circle cx=".957" cy="5.494" r="1" />
          <circle cx="3.728" cy="10.279" r="1" />
          <circle cx="9.258" cy="10.272" r="1" />
          <circle cx="12.016" cy="5.479" r="1" />
          <circle cx="9.245" cy=".694" r="1" />
        </g>
      </g>
    </svg>
  )
}

export function Done() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Create() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Language() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Refresh() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
        fill="currentColor"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgIcon>
  )
}

export function Notifications() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Person() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function AllInclusive() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L12 10.66 10.48 12h.01L7.8 14.39c-.64.64-1.49.99-2.4.99-1.87 0-3.39-1.51-3.39-3.38S3.53 8.62 5.4 8.62c.91 0 1.76.35 2.44 1.03l1.13 1 1.51-1.34L9.22 8.2A5.37 5.37 0 005.4 6.62C2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39c.64-.64 1.49-.99 2.4-.99 1.87 0 3.39 1.51 3.39 3.38s-1.52 3.38-3.39 3.38c-.9 0-1.76-.35-2.44-1.03l-1.14-1.01-1.51 1.34 1.27 1.12a5.386 5.386 0 003.82 1.57c2.98 0 5.4-2.41 5.4-5.38s-2.42-5.37-5.4-5.37z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Article() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Favorite() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Casino() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M19.592 6.8H22.8A1.2 1.2 0 0124 8v12a1.2 1.2 0 01-1.2 1.2H1.2A1.2 1.2 0 010 20V8a1.2 1.2 0 011.2-1.2h3.208A8.4 8.4 0 0112 2a8.4 8.4 0 017.592 4.8zM2.4 9.2h4.8v9.6H2.4zm12 9.6V9.2H9.6v9.6zm2.4-9.6h4.8v9.6h-4.8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}

export function LiveCasino() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M20.485 20.485c-4.686 4.687-12.284 4.687-16.97 0-4.687-4.686-4.687-12.284 0-16.97 4.686-4.687 12.284-4.687 16.97 0 4.687 4.686 4.687 12.284 0 16.97zm-16.07-2.598l1.716-1.715A7.16 7.16 0 014.9 13.2H2.474a9.553 9.553 0 001.94 4.687h.001zM2.476 10.8H4.9a7.162 7.162 0 011.23-2.972L4.417 6.113A9.553 9.553 0 002.475 10.8h.001zm3.638-6.384L7.828 6.13A7.161 7.161 0 0110.8 4.9V2.474a9.553 9.553 0 00-4.687 1.94l.001.002zm10.06 1.715A7.161 7.161 0 0013.2 4.9V2.474a9.553 9.553 0 014.687 1.94L16.172 6.13l.002.001zM13.2 21.525a9.552 9.552 0 004.687-1.94l-1.715-1.716A7.16 7.16 0 0113.2 19.1v2.426-.001zm-2.4 0V19.1a7.162 7.162 0 01-2.972-1.23l-1.715 1.714a9.554 9.554 0 004.687 1.941zm8.784-15.412a9.554 9.554 0 011.941 4.687H19.1a7.162 7.162 0 00-1.23-2.972l1.714-1.715zM19.1 13.2h2.425a9.552 9.552 0 01-1.94 4.687l-1.716-1.715A7.161 7.161 0 0019.1 13.2zm-3.706 2.194a4.8 4.8 0 11-6.788-6.788 4.8 4.8 0 016.788 6.788z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}

export function Store() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}

export function Faq() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M11.5 2C6.81 2 3 5.81 3 10.5S6.81 19 11.5 19h.5v3c4.86-2.34 8-7 8-11.5C20 5.81 16.19 2 11.5 2zm1 14.5h-2v-2h2v2zm0-3.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}

export function Wallet() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
    >
      <g fill="currentColor">
        <path
          d="M10.1825 32.2013V25.455C10.1825 23.1674 11.9569 21.307 14.1987 21.1266L20.4639 10.1836C21.0445 9.17151 21.982 8.44815 23.1038 8.14778C24.2203 7.84945 25.3878 8.00613 26.3871 8.58775L47.8643 21.0914H51.6364C54.0429 21.0914 56 23.0484 56 25.455V51.6364C56 54.0429 54.043 56 51.6364 56H14.5461C12.1395 56 10.1825 54.043 10.1825 51.6364V44.8901C8.91542 44.4383 8.00071 43.2387 8.00071 41.8184V35.273C8.00071 33.8527 8.91542 32.6532 10.1825 32.2013ZM16.7339 21.0914H26.2422L19.1109 16.9396L16.7339 21.0914ZM20.1949 15.0463L30.5784 21.0914H34.8853L21.2718 13.1654L20.1949 15.0463ZM25.2888 10.4733C24.7956 10.1846 24.2193 10.1079 23.6684 10.255C23.1113 10.4041 22.6467 10.7642 22.358 11.2681L22.3558 11.2721L39.2213 21.0914H43.528L25.2888 10.4733ZM12.3643 51.6364C12.3643 52.8391 13.3433 53.8181 14.5461 53.8181H51.6364C52.8391 53.8181 53.8181 52.8391 53.8181 51.6364V25.455C53.8181 24.2523 52.8391 23.2733 51.6364 23.2733H14.5461C13.3433 23.2733 12.3643 24.2523 12.3643 25.455V32.0004H18.9097C22.519 32.0004 25.455 34.9364 25.455 38.5457C25.455 42.1551 22.519 45.0911 18.9097 45.0911H12.3643V51.6364ZM10.1825 41.8184C10.1825 42.4202 10.6714 42.9093 11.2734 42.9093H18.9097C21.3162 42.9093 23.2733 40.9523 23.2733 38.5457C23.2733 36.1392 21.3163 34.1821 18.9097 34.1821H11.2734C10.6715 34.1821 10.1825 34.6711 10.1825 35.273V41.8184Z"
          fill="currentColor"
        />
        <path
          d="M18.91 36.3633C20.1128 36.3633 21.0918 37.3423 21.0918 38.545C21.0918 39.7477 20.1128 40.7268 18.91 40.7268C17.7073 40.7268 16.7283 39.7477 16.7283 38.545C16.7282 37.3423 17.7072 36.3633 18.91 36.3633Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  )
}

export function Ball() {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8612 10.0021C18.8441 13.5915 16.6186 16.9468 13.2461 18.2536C11.3972 18.9669 9.35428 19.0582 7.45405 18.4933C5.78777 17.9968 4.3041 16.9982 3.18564 15.6743C0.823187 12.8724 0.497921 8.73529 2.32968 5.57394C4.22992 2.29845 8.1274 0.540876 11.8423 1.33977C15.4145 2.11014 18.1821 5.04895 18.7585 8.6554C18.8326 9.0948 18.8555 9.55131 18.8612 10.0021C18.8669 10.7382 20.0082 10.7382 20.0025 10.0021C19.9796 5.88208 17.4175 2.1615 13.5827 0.666417C9.59966 -0.885728 4.86904 0.398216 2.20985 3.74218C0.920196 5.36851 0.14983 7.33722 0.0185823 9.41436C-0.106959 11.4344 0.406619 13.4944 1.46801 15.2235C3.6821 18.8299 8.01898 20.6674 12.1561 19.7772C16.0936 18.927 19.2549 15.6058 19.8712 11.617C19.9511 11.0863 20.0025 10.5499 20.0025 10.0078C20.0082 9.26599 18.8669 9.26599 18.8612 10.0021Z"
        fill="currentColor"
      />
      <path
        d="M8.08778 12.6855L6.91797 9.08479L9.98231 6.86499L13.0467 9.08479L11.8768 12.6855H8.08778Z"
        fill="currentColor"
      />
      <path
        d="M8.64105 12.5027C8.25302 11.31 7.85927 10.1117 7.47124 8.91904C7.38564 9.13588 7.29434 9.34702 7.20874 9.56387C8.23019 8.82203 9.25164 8.0859 10.2731 7.34407C10.0791 7.34407 9.89076 7.34407 9.69674 7.34407C10.7182 8.0802 11.7396 8.82203 12.7611 9.55816C12.6755 9.34131 12.5842 9.13018 12.4986 8.91333C12.1106 10.1117 11.7168 11.3043 11.3288 12.5027C11.5114 12.3657 11.694 12.2231 11.8766 12.0861C10.6155 12.0861 9.35436 12.0861 8.08753 12.0861C7.3514 12.0861 7.3514 13.2274 8.08753 13.2274C9.34865 13.2274 10.6098 13.2274 11.8766 13.2274C12.1391 13.2274 12.3502 13.0505 12.4244 12.8108C12.8124 11.6125 13.2062 10.4198 13.5942 9.22148C13.6798 8.96469 13.5315 8.71932 13.3317 8.57666C12.3103 7.84053 11.2888 7.09869 10.2674 6.36257C10.119 6.25414 9.8394 6.25414 9.69103 6.36257C8.66958 7.1044 7.64814 7.84053 6.62669 8.58236C6.42696 8.72502 6.2786 8.9704 6.36419 9.22719C6.75223 10.4198 7.14597 11.6182 7.53401 12.8108C7.76797 13.5013 8.86931 13.2046 8.64105 12.5027Z"
        fill="currentColor"
      />
      <path
        d="M13.1032 1.14144L10.0388 3.35553L6.97449 1.13574L7.00302 1.05585C7.94458 0.741993 8.95461 0.570801 10.0046 0.570801C11.0831 0.570801 12.1217 0.753406 13.0861 1.08438L13.1032 1.14144Z"
        fill="currentColor"
      />
      <path
        d="M12.8124 0.650532C11.7909 1.38666 10.7695 2.1285 9.74804 2.86462C9.94206 2.86462 10.1304 2.86462 10.3244 2.86462C9.30294 2.12279 8.28149 1.38666 7.26004 0.644826C7.34564 0.86167 7.43694 1.07281 7.52254 1.28965C7.53395 1.26112 7.53966 1.23829 7.55107 1.20976C7.41982 1.34101 7.28287 1.47796 7.15162 1.60921C9.04044 0.987211 11.0491 0.998624 12.9322 1.63774C12.801 1.5065 12.664 1.36954 12.5328 1.23829C12.5385 1.25541 12.5442 1.27253 12.5499 1.28965C12.7782 1.98583 13.8795 1.6891 13.6512 0.987211C13.5371 0.633413 13.2746 0.54211 12.9436 0.439395C12.6298 0.342385 12.3159 0.262495 11.9907 0.199725C11.3344 0.068477 10.6668 0 9.99912 0C9.34859 0 8.69235 0.0684771 8.04753 0.194018C7.72226 0.256789 7.4027 0.336679 7.08885 0.433688C6.75788 0.536404 6.53533 0.644826 6.4212 0.987211C6.3356 1.244 6.48397 1.48938 6.68369 1.63204C7.70514 2.37387 8.72659 3.11 9.74804 3.85183C9.89641 3.96026 10.176 3.96026 10.3244 3.85183C11.3458 3.11571 12.3673 2.37387 13.3887 1.63774C13.6398 1.45514 13.7597 1.14128 13.5942 0.855963C13.4515 0.610587 13.0635 0.467927 12.8124 0.650532Z"
        fill="currentColor"
      />
      <path
        d="M3.70469 7.95485L0.651759 10.1803L0.571869 10.1233C0.571869 10.0833 0.571869 10.0377 0.571869 9.99774C0.571869 7.88066 1.26805 5.94619 2.44928 4.34839H2.552L3.70469 7.95485Z"
        fill="currentColor"
      />
      <path
        d="M3.41961 7.46415C2.40386 8.20598 1.38242 8.94782 0.366673 9.68965C0.560691 9.68965 0.749003 9.68965 0.943021 9.68965C0.914489 9.67253 0.891663 9.64971 0.863131 9.63259C0.96014 9.79807 1.05144 9.96356 1.14845 10.1233C1.13133 8.13751 1.76475 6.23727 2.94597 4.64518C2.78049 4.74219 2.615 4.83349 2.45522 4.9305C2.48946 4.9305 2.5237 4.9305 2.55794 4.9305C2.37533 4.79355 2.19273 4.65089 2.01012 4.51393C2.39816 5.71228 2.78049 6.91063 3.16853 8.10898C3.39108 8.80516 4.49241 8.50842 4.26986 7.80653C3.88183 6.60819 3.4995 5.40984 3.11146 4.21149C3.03157 3.97182 2.82043 3.79492 2.56364 3.79492C2.18131 3.79492 2.01012 4.01177 1.80469 4.30279C1.61638 4.56529 1.44519 4.8392 1.28541 5.12452C0.965847 5.69516 0.703352 6.30004 0.49792 6.92204C0.292489 7.54404 0.161242 8.18887 0.0813516 8.8394C0.0414067 9.18178 0.0185812 9.51846 0.0128748 9.86085C0.0128748 10.0263 -0.00424476 10.1975 0.0585259 10.3516C0.121296 10.5057 0.241131 10.5913 0.372379 10.6826C0.520746 10.791 0.80036 10.791 0.948727 10.6826C1.96447 9.94074 2.98592 9.1989 4.00166 8.45707C4.25274 8.27446 4.37258 7.96061 4.20709 7.67529C4.05873 7.4242 3.67069 7.28154 3.41961 7.46415Z"
        fill="currentColor"
      />
      <path
        d="M7.3 18.991L7.25435 19.0252C5.23999 18.4146 3.51094 17.1478 2.3183 15.4701L2.34683 15.3845L6.12448 15.3959L7.3 18.991Z"
        fill="currentColor"
      />
      <path
        d="M7.01455 18.5002C6.99743 18.5116 6.98602 18.5231 6.9689 18.5345C7.11726 18.5173 7.25992 18.4945 7.40829 18.4774C5.57082 17.9068 3.93879 16.7484 2.81462 15.1848C2.83174 15.3332 2.85457 15.4758 2.87169 15.6242C2.8831 15.5957 2.88881 15.5671 2.90022 15.5386C2.71761 15.6755 2.53501 15.8182 2.3524 15.9552C3.61352 15.9609 4.86893 15.9609 6.13005 15.9666C5.94745 15.8296 5.76484 15.687 5.58224 15.55C5.97598 16.7484 6.36402 17.9467 6.75776 19.1451C6.98602 19.8412 8.08735 19.5445 7.8591 18.8426C7.46535 17.6443 7.07732 16.4459 6.68358 15.2476C6.60369 15.0079 6.39255 14.831 6.13576 14.831C4.87464 14.8253 3.61923 14.8253 2.35811 14.8196C2.09561 14.8196 1.88448 14.9965 1.81029 15.2362C1.69617 15.5957 1.89018 15.8353 2.09561 16.1035C2.29534 16.3603 2.50077 16.6057 2.71761 16.8397C3.1456 17.3019 3.62493 17.7127 4.13281 18.0837C4.62356 18.4489 5.15426 18.7627 5.70778 19.0252C5.99881 19.1622 6.29554 19.2877 6.59798 19.3961C6.91183 19.5103 7.29416 19.7043 7.60231 19.4817C7.85339 19.2991 7.97323 18.9853 7.80774 18.7C7.64796 18.4603 7.26563 18.3176 7.01455 18.5002Z"
        fill="currentColor"
      />
      <path
        d="M17.7368 15.4014C16.5499 17.0962 14.8208 18.3858 12.8007 19.0135L12.7722 18.9964L13.9477 15.4071L17.7311 15.4014H17.7368Z"
        fill="currentColor"
      />
      <path
        d="M17.2405 15.1106C16.1221 16.6912 14.4958 17.8782 12.6469 18.4602C12.7952 18.4774 12.9379 18.5002 13.0863 18.5173C13.0749 18.5116 13.0692 18.5059 13.0577 18.5002C13.1433 18.717 13.2346 18.9282 13.3202 19.145C13.714 17.9467 14.1077 16.7483 14.4958 15.5557C14.3132 15.6926 14.1305 15.8353 13.9479 15.9722C15.2091 15.9722 16.4702 15.9665 17.7313 15.9665C17.5487 15.8296 17.3661 15.6869 17.1835 15.55V15.5557C17.4003 16.2576 18.5017 15.9551 18.2848 15.2532V15.2475C18.2106 15.0078 17.9881 14.8309 17.737 14.8309C16.4759 14.8309 15.2148 14.8367 13.9536 14.8367C13.6912 14.8367 13.48 15.0136 13.4058 15.2532C13.0121 16.4516 12.6183 17.6499 12.2303 18.8426C12.1447 19.0994 12.2931 19.339 12.4928 19.4874C12.7667 19.6871 13.1091 19.5216 13.3887 19.4189C13.674 19.3162 13.9536 19.1964 14.2333 19.0708C14.7583 18.8311 15.2604 18.5401 15.7341 18.2034C16.7098 17.513 17.5487 16.6684 18.2392 15.6869C18.4161 15.4358 18.2848 15.0535 18.0337 14.9051C17.737 14.7396 17.4232 14.8595 17.2405 15.1106Z"
        fill="currentColor"
      />
      <path
        d="M19.4317 10.0034C19.4317 10.0604 19.4317 10.1232 19.4317 10.1803L19.4259 10.186L16.373 7.95475L17.5371 4.354H17.5599C18.7355 5.9518 19.4317 7.88628 19.4317 10.0034Z"
        fill="currentColor"
      />
      <path
        d="M18.8612 10.0036C18.8612 10.0607 18.8612 10.1234 18.8612 10.1805C18.9582 10.015 19.0495 9.84952 19.1465 9.68974L19.1408 9.69545C19.3348 9.69545 19.5231 9.69545 19.7172 9.69545C18.7014 8.95362 17.6857 8.20608 16.6642 7.46424C16.7498 7.68108 16.8411 7.89222 16.9267 8.10907C17.3148 6.91072 17.7028 5.71237 18.0908 4.50832C17.9082 4.64527 17.7256 4.78793 17.543 4.92488C17.5487 4.92488 17.5601 4.92488 17.5658 4.92488C17.4003 4.82787 17.2349 4.73657 17.0751 4.63956C18.2221 6.20883 18.8498 8.05771 18.8612 10.0036C18.8669 10.7397 20.0082 10.7397 20.0025 10.0036C19.9968 8.83378 19.8028 7.65826 19.3919 6.55692C19.1979 6.03193 18.9639 5.51835 18.6843 5.03331C18.5473 4.79364 18.399 4.55967 18.2392 4.33142C18.0566 4.06892 17.8911 3.78931 17.5373 3.78931C17.2748 3.78931 17.0637 3.9662 16.9895 4.20587C16.6014 5.40422 16.2134 6.60257 15.8254 7.80663C15.7398 8.06341 15.8881 8.30308 16.0879 8.45145C17.1036 9.19329 18.1194 9.94083 19.1408 10.6827C19.2892 10.7911 19.5688 10.7911 19.7172 10.6827C19.9682 10.5001 20.0082 10.3003 20.0082 10.0093C20.0082 9.26747 18.8669 9.26747 18.8612 10.0036Z"
        fill="currentColor"
      />
      <path
        d="M9.41138 3.44101C9.41138 4.52523 9.41138 5.60945 9.41138 6.69367C9.41138 7.4298 10.5527 7.4298 10.5527 6.69367C10.5527 5.60945 10.5527 4.52523 10.5527 3.44101C10.5527 2.70488 9.41138 2.70488 9.41138 3.44101Z"
        fill="currentColor"
      />
      <path
        d="M16.219 7.40668C15.112 7.7776 14.0049 8.14852 12.8922 8.51943C12.2017 8.7534 12.4984 9.85474 13.1946 9.62077C14.3017 9.24986 15.4087 8.87894 16.5215 8.50802C17.2176 8.27406 16.9152 7.17272 16.219 7.40668Z"
        fill="currentColor"
      />
      <path
        d="M14.4444 15.1108C13.754 14.1977 13.0578 13.2847 12.3673 12.366C12.179 12.1206 11.8766 11.9951 11.5855 12.1606C11.3459 12.3032 11.1918 12.6913 11.3801 12.9423C12.0706 13.8554 12.7668 14.7684 13.4572 15.6871C13.6455 15.9325 13.948 16.058 14.239 15.8926C14.4787 15.7499 14.6328 15.3561 14.4444 15.1108Z"
        fill="currentColor"
      />
      <path
        d="M6.61514 15.6871C7.27138 14.774 7.92191 13.861 8.57815 12.9423C8.75505 12.6912 8.6238 12.3089 8.37272 12.1605C8.0874 11.995 7.77355 12.1148 7.59094 12.3659C6.9347 13.279 6.28417 14.192 5.62793 15.1107C5.45104 15.3618 5.58228 15.7441 5.83337 15.8925C6.12439 16.058 6.43825 15.9381 6.61514 15.6871Z"
        fill="currentColor"
      />
      <path
        d="M3.55653 8.50836C4.62934 8.87928 5.69644 9.25019 6.76924 9.62682C7.06027 9.72953 7.39124 9.51269 7.47113 9.22737C7.55673 8.91352 7.36271 8.6282 7.07168 8.52548C5.99888 8.15456 4.93178 7.78364 3.85897 7.40702C3.56794 7.30431 3.23697 7.52115 3.15708 7.80647C3.07148 8.12032 3.2655 8.40564 3.55653 8.50836Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Moments() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 20"
    >
      <path
        d="M18.9202 2.67794C18.1383 1.91273 17.2566 1.5433 16.3337 1.5896C16.2736 1.59217 16.2113 1.59677 16.1452 1.6043V0.526972C16.1452 0.236368 15.902 0 15.6031 0L4.39737 0.00018381C4.09853 0.00018381 3.85527 0.236562 3.85527 0.527156V1.60411C3.79002 1.59694 3.72844 1.59216 3.66763 1.58959C2.72803 1.54529 1.8599 1.91475 1.08018 2.67793C0.0309681 3.70449 -0.27658 5.24372 0.257416 6.79452C0.912247 8.69748 2.57715 10.1935 4.71708 10.8056C5.50975 12.1079 6.80108 13.0878 8.27986 13.5107V15.0475H6.603C6.45937 15.0475 6.3196 15.1045 6.21824 15.2032L4.87285 16.5202C4.84235 16.55 4.81493 16.5853 4.78521 16.6356C4.78405 16.6373 4.77227 16.6599 4.77131 16.6614C4.74833 16.7077 4.73366 16.7481 4.72459 16.7963C4.7188 16.8274 4.71494 16.8593 4.71494 16.8919V18.473C4.71494 18.7636 4.95818 19 5.25704 19H14.7424C15.0413 19 15.2845 18.7636 15.2845 18.473L15.2832 16.963C15.2869 16.9395 15.2898 16.9163 15.2898 16.8919C15.2898 16.7259 15.212 16.573 15.0905 16.4848L13.7809 15.2031C13.6799 15.1042 13.5398 15.0474 13.3961 15.0474H11.7193L11.7194 13.5107C13.1982 13.0882 14.4896 12.1081 15.2824 10.8056C17.4218 10.1937 19.0867 8.69787 19.7423 6.79453C20.2766 5.24373 19.9693 3.70488 18.9201 2.67795L18.9202 2.67794ZM18.2045 6.29202C17.8682 7.26861 17.0748 8.17734 16.0541 8.76793C16.1147 8.4266 16.1453 8.08233 16.1453 7.74081V3.20308C16.2346 3.18378 16.3203 3.17275 16.4051 3.16907L16.4671 3.16779C16.914 3.16779 17.34 3.37218 17.77 3.7931C18.3852 4.39546 18.5437 5.30621 18.2045 6.29197L18.2045 6.29202ZM2.23063 3.79348C2.66132 3.37219 3.08294 3.16741 3.52003 3.16741C3.54281 3.16741 3.56578 3.16796 3.58972 3.16925C3.67582 3.17274 3.76327 3.18395 3.85536 3.20344V7.74117C3.85536 8.08324 3.88586 8.42733 3.94667 8.7681C2.92603 8.17789 2.13217 7.26897 1.79626 6.29219C1.45707 5.30681 1.61537 4.39601 2.23063 3.79346V3.79348Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export function Star() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
