import * as Urql from 'urql'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Icons from './icons'
import * as Logger from './logger'

const ServerConfigurationContext = React.createContext()

const configurationQuery = `
  query Configuration {
    configuration {
      brand
      cdnHost
      cdnPrefix
      countries {
        alpha2
        countryCode
        name
        locales {
          code
          default
          language
          slug
        }
        loggedOutPhoneVerification
      }
      country {
        access
        alpha2
        countryCode
        enabled
        name
        localCurrency
        locales {
          language
          slug
        }
        loggedOutPhoneVerification
      }
      currency {
        code
      }
      currencies {
        code
        ratio
        symbol
      }
      locale {
        code
        language
        slug
      }
      liveChatKey
      liveChatDepartment {
        fallback
        primary
      }
      supportEmail
    }
  }
`

export function Provider(props) {
  const [response] = Urql.useQuery({ query: configurationQuery })

  if (response.error) {
    Logger.react.error(response.error)
    throw new Error('errors.configuration.fetch-failure')
  }

  if (!response.data?.configuration) {
    return <Icons.Spinner />
  }

  return (
    <ServerConfigurationContext.Provider value={response.data.configuration}>
      {props.children}
    </ServerConfigurationContext.Provider>
  )
}

Provider.displayName = 'ServerConfiguration.Provider'

Provider.propTypes = {
  children: PropTypes.node,
}

export function useContext() {
  return React.useContext(ServerConfigurationContext)
}
