import * as React from 'react'

import * as common from '@rushplay/common'
import * as notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { useI18n } from '@rushplay/i18n'

import { HtmlContent } from './html-content'
import { Toast } from './toast'

const bounceAnimation = keyframes`
0% {
  transform: translateX(100%);
  opacity: 0;
}
30% {
  transform: translateX(-8%);
  opacity: 1;
}
60% {
  transform: translateX(-3%);
  opacity: 1;
}
40%, 75%, 100% {
  transform: translateX(0);
  opacity: 1;
}
`

const Transition = styled.div`
  animation-name: ${bounceAnimation};
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.3, 0.5, 0.6, 1);
`

export function NotificationsContainer() {
  const i18n = useI18n()

  return (
    <common.Box
      position="fixed"
      top={['48px', '0px']}
      right="0px"
      width={['100%', 'auto']}
      minWidth={[null, '350px']}
      maxWidth="420px"
      zIndex="9999"
    >
      <notifications.Notifications mountPoint="notifications">
        {notificationIds =>
          notificationIds.map(notificationId => (
            <Transition key={notificationId}>
              <notifications.Notification
                id={notificationId}
                mountPoint="notifications"
              >
                {notification => (
                  <common.Space pt={1} px={1}>
                    <Toast
                      variant={notification.level}
                      title={i18n.translate(`toast.${notification.level}`)}
                      onDismiss={() => notification.onDismiss(notificationId)}
                    >
                      <HtmlContent
                        html={{
                          __html: notification.contentTranslated
                            ? notification.message
                            : i18n.translate(
                                notification.message,
                                notification.variables
                              ),
                        }}
                      />
                    </Toast>
                  </common.Space>
                )}
              </notifications.Notification>
            </Transition>
          ))
        }
      </notifications.Notifications>
    </common.Box>
  )
}
