// TODO: Get from BE
export function getMinDepositAmountInCents(currency) {
  switch (currency) {
    case 'CAD':
      return 100000
    case 'SEK':
      return 10000
    case 'EUR':
    default:
      return 1000
  }
}

// TODO: Get from BE
export function getInitialAmount() {
  return 10000
}

// TODO: Get from BE
export function getInitialAmounts(countryCode) {
  if (countryCode === 'FI') {
    return [10000, 20000, 50000]
  } else {
    return [5000, 10000, 20000]
  }
}

export function normalizeAmount(value) {
  const amount = Number.parseInt(value, 10)
  if (Number.isNaN(amount)) {
    return 0
  }
  return Math.round(amount * 100)
}

export function parseAmount(value) {
  const amount = Math.round(value / 100)
  if (Number.isNaN(amount)) {
    return ''
  }

  return amount
}
