import * as React from 'react'
import PropTypes from 'prop-types'

import * as ServerConfiguration from './server-configuration'

export function Amount(props) {
  const configuration = ServerConfiguration.useContext()

  const value = new Intl.NumberFormat(
    props.locale || configuration?.locale?.code,
    {
      currency: props.currency || configuration?.currency?.code,
      minimumFractionDigits: props.minimumFractionDigits,
      maximumFractionDigits: props.maximumFractionDigits ?? 2,
      style: 'currency',
    }
  ).format(props.children / 100)

  return <span>{value}</span>
}

Amount.propTypes = {
  currency: PropTypes.string,
  locale: PropTypes.string,
  children: PropTypes.number.isRequired,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
}
