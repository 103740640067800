import * as React from 'react'
import PropTypes from 'prop-types'
import { typography } from 'styled-system'

import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as icons from './icons'

const Input = styled.input`
  opacity: 0;
  margin-right: -1.2em;
`

const InputBase = styled.div`
  border-radius: 0.1em;
  border: 0.125em solid rgba(0, 0, 0, 0.7);
  background-color: white;
  height: 1.25em;
  width: 1.25em;
  min-width: 1.25em;
  position: relative;
  padding: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;

  ${css({
    'input[type=checkbox]:checked + &': {
      borderColor: 'checkbox-checked',
      bg: 'checkbox-checked',
      color: 'g-text',
    },
    'input[type=checkbox]:not([checked]):disabled + &': {
      bg: 'white',
      borderColor: 'disabled',
      cursor: 'not-allowed',
    },
    'input[type=checkbox]:disabled:checked + &': {
      bg: 'disabled',
      borderColor: 'disabled',
      cursor: 'not-allowed',
      color: 'g-text',
    },
  })};
  ${props => props.focus && 'border-color: #0D989F !important;'}
  ${typography}
`

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  pointer-events: inherit;
  padding: 2px;
`

export function Checkbox(props) {
  const [focus, setFocus] = React.useState(false)

  return (
    <Label data-testid={`${props.name}.checkbox.label`}>
      <Input
        name={props.name}
        type="checkbox"
        checked={props.checked}
        disabled={props.disabled}
        value={props.value}
        data-testid={`${props.name}.checkbox`}
        onChange={props.onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <InputBase fontSize={props.fontSize} focus={focus}>
        <div>{props.checked && <icons.Done />}</div>
      </InputBase>
      {props.label && (
        <common.Box pl={1} color="g-text" fontSize={2}>
          {props.label}
        </common.Box>
      )}
    </Label>
  )
}

Checkbox.propTypes = {
  fontSize: 2,
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
}
