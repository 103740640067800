import React from 'react'

import { ExternalFont } from './external-font'

export function Fonts() {
  return (
    <ExternalFont href="https://fonts.googleapis.com/css?family=Poppins:400,600,700,800&display=swap" />
  )
}

// for @loadable/components
export default Fonts
